.select {
  &__control {
    background: var(--surface-secondary) !important;
    border-color: transparent !important;
    border-radius: 5px !important;
    box-shadow: none !important;

    &_active {
      box-shadow: none !important;
      border: 1px solid var(--on-surface-primary) !important;
    }

    input {
      color: var(--on-surface-primary) !important;
    }
  }

  &__menu {
    margin-top: 0 !important;
    background: var(--surface-primary) !important;
    border-radius: 5px;
    padding: 8px;
  }

  &__single_value {
    color: var(--on-surface-primary) !important;
  }

  &__option {
    margin-bottom: 12px;
    color: var(--on-surface-primary) !important;
    background: none !important;
    padding: 6px 8px !important;
    cursor: pointer !important;
    transition: .2s background-color;
    line-height: 1.2;
    border-radius: 2px;

    &:hover {
      background: var(--surface-accent) !important;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__placeholder {
    color: var(--on-surface-secondary) !important;
  }

  &__clear_indicator {
    color: var(--on-surface-secondary) !important;
    cursor: pointer;
    padding-left: 2px !important;

    &:hover {
      color: var(--on-surface-primary) !important;
    }
  }
}

.error_message {
  padding-top: 8px;
  padding-bottom: 2px;
}

.indicators_container {
  padding: 0 16px 0 0;
  display: flex;
  align-items: center;
  gap: 4px;

  &_no_indicator_container {
    padding-right: 0;
  }
}

.dropdown_indicator {
  transition: .2s transform !important;
  transform: rotate(180deg);
  width: 16px;
  color: var(--on-surface-primary);

  &_collapsed {
    transform: rotate(0deg);
  }
}

.multi_value {
  background: var(--surface-accent) !important;
  border: 1px solid var(--on-surface-accent) !important;
  border-radius: 8px !important;

  >div {
    color: var(--on-surface-accent);
  }
}

.value_container_with_icon {
  >div:not(.multi_value):not(.icon_container) {
    margin-left: 34px;
  }
}

.icon_container {
  grid-area: 1 / 1 / 2 / 3;

  display: flex;
  align-items: center;
}

.search_icon {
  color: var(--on-surface-primary);
}
